//获取场地列表
const getAreaListUrl = `/gateway/hc-serve/miniapi/reservation/placeList`;
//获取场地详情
const getAreaDetailUrl = `/gateway/hc-serve/miniapi/reservation/placeDetailInfo`;
//场地类型接口
const getAreaTypeUrl = `/gateway/hc-serve/miniapi/reservation/placeTypeList`;
//场次列表
const getSessionUrl = `/gateway/hc-serve/miniapi/reservation/placeScheduleList`;
//预约场次
const getAppointUrl = `/gateway/hc-serve/miniapi/reservation/addOrder`;
//我的预约
const myAppointUrl = `/gateway/hc-serve/miniapi/reservation/placeOrderList`;
//预约详情
const myAppointDetailUrl = `/gateway/hc-serve/miniapi/reservation/placeOrderDetail`;
//取消预约
const cancelAppointUrl = `/gateway/hc-serve/miniapi/reservation/cancelOrder`;
//我的租赁
const myrentUrl = `/gateway/hc-space/space/getMsRentApplyList`;
// 常用场馆展示
const showCommonPlaceUrl = `/gateway/hc-serve/miniapi/reservation/show-common-place`;
//获取区县/街道/社区
const getStreetCommunityUrl = `/gateway/hc-serve/manageapi/place/get-street-community-list`;
// 获取场馆类型列表
const placeTypeListUrl = `/gateway/hc-serve/manageapi/placeType/placeTypeList`;
// 获取场次日期
const getPlaceScheduleDateList = `/gateway/hc-serve/miniapi/reservation/placeScheduleDateList`;
//用户信息
const userInfoUrl = `/gateway/hc-mini/user/mini/user-info`;
// 获取社区
const getPlaceList = `/gateway/hc-serve/manageapi/place/getCommunityListByTenantId`;
export {
  userInfoUrl,
  getAreaListUrl,
  getAreaTypeUrl,
  getAreaDetailUrl,
  getSessionUrl,
  getAppointUrl,
  myAppointUrl,
  myAppointDetailUrl,
  cancelAppointUrl,
  myrentUrl,
  showCommonPlaceUrl,
  getStreetCommunityUrl,
  placeTypeListUrl,
  getPlaceScheduleDateList,
  getPlaceList,
};
